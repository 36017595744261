<template>
  <div class="church-details template-2 convention-promoedit-wrapper">
    <div class="page-body container mx-lg">
      <div class="row">
        <form class="convention-promoedit">
          <b-row>
            <b-col sm="12">
              <div class="details section-top">
                <h2 class="main-title">
                  {{ translations.tcAddEditConventionConferenceBlitzes }}
                </h2>
                <div class="form-group">
                  <h5 class="custom-form-label">{{ translations.tcEventName }}</h5>
                  <b-form-input
                    :title="translations.tcName"
                    id="event-name"
                    :placeholder="translations.tcName"
                    v-model="convention.evn_name"
                    required
                  ></b-form-input>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6" class="flex-it">
              <div class="details section-2 section-left">
                <h5 class="main-title">{{ translations.tcAdministration }}</h5>
                <div class="form-group custom-date-section">
                  <h5 class="custom-form-label">{{ translations.tcStartDate }}</h5>
                  <b-form-datepicker
                    id="startDate"
                    v-model="convention.evn_start_date"
                    class="mb-2 contact_date"
                    :locale="prefCulture"
                    :label-help="translations.tcCalHelp"
                    :label-no-date-selected="translations.tcDatePickerPlaceholder"
                    :placeholder="translations.tcDatePickerPlaceholder"
                    :show-decade-nav="showDecadeNav"
                    :hide-header="hideHeader"
                  >
                    <template #button-content style="padding: 12px">
                      <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                    </template>
                  </b-form-datepicker>
                </div>
                <div class="form-group custom-date-section">
                  <h5 class="custom-form-label">{{ translations.tcEndDate }}</h5>
                  <b-form-datepicker
                    id="endDate"
                    v-model="convention.evn_end_date"
                    class="mb-2 contact_date"
                    :locale="prefCulture"
                    :label-help="translations.tcCalHelp"
                    :label-no-date-selected="translations.tcDatePickerPlaceholder"
                    :placeholder="translations.tcDatePickerPlaceholder"
                    :show-decade-nav="showDecadeNav"
                    :hide-header="hideHeader"
                  >
                    <template #button-content style="padding: 12px">
                      <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                    </template>
                  </b-form-datepicker>
                </div>
                <div class="form-group active-chk-box-section">
                  <h5 class="custom-form-label">&nbsp;</h5>
                  <h5 class="chk-label custom-form-label">
                    {{ translations.tcMakePageActive }}
                  </h5>
                  <input type="checkbox" name="cb_active" id="cb_active" v-model="convention.evn_active_flag" />
                </div>
                <div class="form-group">
                  <h5 class="custom-form-label">
                    {{ translations.tcRegistrationLink }}
                  </h5>
                  <b-form-input
                    :title="translations.tcRegistrationLink"
                    id="reg-link"
                    :placeholder="translations.tcRegistrationLink"
                    v-model="convention.evn_registration_link"
                  ></b-form-input>
                </div>
              </div>
            </b-col>
            <b-col sm="6" class="flex-it">
              <div class="details section-3 common-section section-right">
                <h5 class="main-title">
                  {{ translations.tcEventLocaton }}
                </h5>
                <div class="form-group">
                  <h5 class="custom-form-label">{{ translations.tcName }}</h5>
                  <b-form-input
                    :title="translations.tcName"
                    id="loc-name"
                    :placeholder="translations.tcName"
                    v-model="convention.evl_name"
                    required
                  ></b-form-input>
                </div>
                <div class="form-group">
                  <h5 class="custom-form-label">
                    {{ translations.tcAddress1 }}
                  </h5>
                  <b-form-input
                    :title="translations.tcAddress1"
                    id="loc-addr-1"
                    :placeholder="translations.tcAddress1"
                    v-model="convention.evl_adr_line1"
                  ></b-form-input>
                </div>
                <div class="form-group">
                  <h5 class="custom-form-label">
                    {{ translations.tcAddress2 }}
                  </h5>
                  <b-form-input
                    :title="translations.tcAddress2"
                    id="loc-addr-2"
                    :placeholder="translations.tcAddress2"
                    v-model="convention.evl_adr_line2"
                  ></b-form-input>
                </div>
                <div class="form-group event-city">
                  <h5 class="custom-form-label">{{ translations.tcCity }}</h5>
                  <b-form-input
                    :title="translations.tcCity"
                    id="loc-city"
                    :placeholder="translations.tcCity"
                    v-model="convention.evl_city"
                  ></b-form-input>
                </div>
                <div class="form-group selectdiv event-state">
                  <h5 class="custom-form-label">{{ translations.tcState }}</h5>
                  <b-form-select
                    id="loc-state-select"
                    name="loc-state-select"
                    :title="translations.tcState"
                    v-model="convention.evl_state"
                    :options="optionsStates"
                    class="flex-0 mr-3 form-control"
                  ></b-form-select>
                </div>
                <div class="form-group event-zip">
                  <h5 class="custom-form-label">{{ translations.tcZip }}</h5>
                  <b-form-input
                    :title="translations.tcZipCode"
                    id="loc-postal"
                    :placeholder="translations.tcZipCode"
                    v-model="convention.evl_postal_code"
                  ></b-form-input>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6" class="flex-it">
              <div class="details section-6 common-section section-left">
                <h5 class="main-title">
                  {{ translations.tcGideonAuxiliaryContact }}
                </h5>
                <div class="form-group">
                  <h5 class="custom-form-label">{{ translations.tcName }}</h5>
                  <b-form-input
                    :title="translations.tcName"
                    id="con-name"
                    :placeholder="translations.tcName"
                    v-model="convention.evc_name"
                  >
                  </b-form-input>
                </div>
                <div class="form-group">
                  <h5 class="custom-form-label">
                    {{ translations.tcAddress1 }}
                  </h5>
                  <b-form-input
                    :title="translations.tcAddress1"
                    id="con-addr-1"
                    :placeholder="translations.tcAddress1"
                    v-model="convention.evc_adr_line1"
                  ></b-form-input>
                </div>
                <div class="form-group">
                  <h5 class="custom-form-label">
                    {{ translations.tcAddress2 }}
                  </h5>
                  <b-form-input
                    :title="translations.tcAddress2"
                    id="con-addr-2"
                    :placeholder="translations.tcAddress2"
                    v-model="convention.evc_adr_line2"
                  ></b-form-input>
                </div>
                <div class="form-group event-city">
                  <h5 class="custom-form-label">
                    {{ translations.tcCity }}
                  </h5>
                  <b-form-input
                    :title="translations.tcCity"
                    id="con-city"
                    :placeholder="translations.tcCity"
                    v-model="convention.evc_city"
                  >
                  </b-form-input>
                </div>
                <div class="form-group selectdiv event-state">
                  <h5 class="custom-form-label">
                    {{ translations.tcState }}
                  </h5>
                  <b-form-select
                    id="con-state-select"
                    name="con-state-select"
                    :title="translations.tcState"
                    v-model="convention.evc_state"
                    :options="optionsStates"
                    class="flex-0 mr-3 form-control"
                  ></b-form-select>
                </div>
                <div class="form-group event-zip">
                  <h5 class="custom-form-label">{{ translations.tcZip }}</h5>
                  <b-form-input
                    :title="translations.tcZip"
                    id="con-postal"
                    :placeholder="translations.tcZipCode"
                    v-model="convention.evc_postal_code"
                  ></b-form-input>
                </div>
                <div class="form-group email-field">
                  <h5 class="custom-form-label">{{ translations.tcEmail }}</h5>
                  <b-form-input
                    :title="translations.tcEmail"
                    id="con-email"
                    :placeholder="translations.tcEmail"
                    v-model="convention.evc_email"
                  ></b-form-input>
                </div>
                <div class="form-group">
                  <h5 class="custom-form-label">{{ translations.tcPhone }}</h5>
                  <b-form-input
                    :title="translations.tcPhone"
                    id="con-phone"
                    :placeholder="translations.tcPhone"
                    v-model="convention.evc_phone_number"
                  ></b-form-input>
                </div>
                <div class="form-group">
                  <h5 class="custom-form-label">
                    {{ translations.tcOtherInformation }}
                  </h5>
                  <b-form-input
                    :title="translations.tcOtherInformation"
                    id="con-other"
                    :placeholder="translations.tcOtherInformation"
                    v-model="convention.evc_other_info"
                  ></b-form-input>
                </div>
              </div>
            </b-col>
            <b-col sm="6" class="flex-it">
              <div class="details section-7 common-section section-right">
                <h5 class="main-title">{{ translations.tcHotelInformation }}</h5>
                <div class="form-group">
                  <h5 class="custom-form-label">{{ translations.tcName }}</h5>
                  <b-form-input
                    :title="translations.tcName"
                    id="hot-name"
                    :placeholder="translations.tcName"
                    v-model="convention.evh_name"
                  ></b-form-input>
                </div>
                <div class="form-group">
                  <h5 class="custom-form-label">{{ translations.tcAddress1 }}</h5>
                  <b-form-input
                    :title="translations.tcAddress1"
                    id="hot-adr-1"
                    :placeholder="translations.tcAddress1"
                    v-model="convention.evh_adr_line1"
                  ></b-form-input>
                </div>
                <div class="form-group">
                  <h5 class="custom-form-label">{{ translations.tcAddress2 }}</h5>
                  <b-form-input
                    :title="translations.tcAddress2"
                    id="hot-adr-2"
                    :placeholder="translations.tcAddress2"
                    v-model="convention.evh_adr_line2"
                  ></b-form-input>
                </div>
                <div class="form-group event-city">
                  <h5 class="custom-form-label">{{ translations.tcCity }}</h5>
                  <b-form-input
                    :title="translations.tcCity"
                    id="hot-city"
                    :placeholder="translations.tcCity"
                    v-model="convention.evh_city"
                  ></b-form-input>
                </div>
                <div class="form-group selectdiv event-state">
                  <h5 class="custom-form-label">{{ translations.tcState }}</h5>
                  <b-form-select
                    id="hot-state-select"
                    name="hot-state-select"
                    :title="translations.tcState"
                    v-model="convention.evh_state"
                    :options="optionsStates"
                    class="flex-0 mr-3 form-control"
                  ></b-form-select>
                </div>
                <div class="form-group event-zip">
                  <h5 class="custom-form-label">{{ translations.tcZip }}</h5>
                  <b-form-input
                    :title="translations.tcZip"
                    id="hot-postal"
                    :placeholder="translations.tcZipCode"
                    v-model="convention.evh_postal_code"
                  ></b-form-input>
                </div>
                <div class="form-group email-field">
                  <h5 class="custom-form-label">{{ translations.tcEmail }}</h5>
                  <b-form-input
                    :title="translations.tcEmail"
                    id="hot-email"
                    :placeholder="translations.tcEmail"
                    v-model="convention.evh_email"
                  ></b-form-input>
                </div>
                <div class="form-group">
                  <h5 class="custom-form-label">{{ translations.tcPhone }}</h5>
                  <b-form-input
                    :title="translations.tcPhone"
                    id="hot-phone"
                    :placeholder="translations.tcPhone"
                    v-model="convention.evh_phone_number"
                  ></b-form-input>
                </div>
                <div class="form-group">
                  <h5 class="custom-form-label">
                    {{ translations.tcWebsite }}
                  </h5>
                  <b-form-input
                    :title="translations.tcWebsite"
                    id="hot-website"
                    :placeholder="translations.tcWebsite"
                    v-model="convention.evh_website"
                  ></b-form-input>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6" class="flex-it">
              <div class="details section-4 section-left">
                <h5 class="main-title">{{ translations.tcPhotos }}</h5>
                <div id="" class="custom-list-section">
                  <div class="list-area">
                    <div class="card-table">
                      <div class="card-row" v-for="(item, index) in convention.photos" :key="index + 'oldphotos'">
                        <div class="table-item">
                          <div class="first-field">
                            <iPaperclip />
                            {{ item.evp_image_name }}
                          </div>
                          <div class="third-field photoOptions">
                            <div @click="editThisPhoto(item)">
                              <ServiceEdit />
                            </div>
                            <div @click="deleteExistingPhoto(item)">
                              <ServiceDelete />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-row" v-for="(item, index) in uploadedPhotos" :key="index">
                        <div class="table-item">
                          <div class="first-field">
                            <iPaperclip />
                            {{ item.photoName }}
                          </div>
                          <div class="third-field photoOptions">
                            <div @click="deleteNewPhoto(index)">
                              <ServiceDelete />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showPhotoEdit" class="form-group">
                  <h5 class="custom-form-label">
                    {{ translations.tcPhotoName }}
                  </h5>
                  <b-form-input
                    :title="translations.tcPhotoName"
                    id="photo-name"
                    :placeholder="translations.tcPhotoName"
                    v-model="editPhotoFile.evp_image_name"
                    required
                    class="mb-1"
                  ></b-form-input>
                  <b-button @click="onPhotoEdit" variant="primary" size="sm" class="btn btn-primary">
                    {{ translations.tcSave }}
                  </b-button>
                  <b-button @click="onPhotoCancel" size="sm" class="ml-1 btn btn-tertiary" variant="tertiary">
                    {{ translations.tcCancel }}
                  </b-button>
                </div>
                <div v-if="showPhotoUpload" class="form-group">
                  <h5 class="custom-form-label">
                    {{ translations.tcPhotoName }}
                  </h5>
                  <b-form-input
                    :title="translations.tcPhotoName"
                    id="photo-name"
                    :placeholder="translations.tcPhotoName"
                    v-model="photoFileName"
                    required
                  ></b-form-input>

                  <h5 class="custom-form-label mt-2">
                    {{ translations.tcFile }}
                  </h5>
                  <b-form-file
                    class="mb-1 mt-1"
                    style="text-transform: uppercase"
                    ref="fileUploadPhoto"
                    v-model.lazy="photoFile"
                    name="photo"
                    :browse-text="translations.tcBrowse"
                    :placeholder="translations.tcPhoto"
                    :drop-placeholder="translations.tcDropFileHere"
                    accept=".jpg, .png, .gif"
                  />
                  <b-button @click="onPhotoUpload" variant="primary" size="sm" class="btn btn-primary">
                    {{ translations.tcSave }}
                  </b-button>
                  <b-button @click="onPhotoCancel" size="sm" class="ml-1 btn btn-tertiary" variant="tertiary">
                    {{ translations.tcCancel }}
                  </b-button>
                </div>
                <div class="divButton">
                  <b-button variant="primary" size="sm" @click="showPhotoUpload = true" v-if="!showPhotoUpload">
                    {{ translations.tcAddPhoto }}
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col sm="6" class="flex-it">
              <div class="details section-5 section-right">
                <h5 class="main-title">
                  {{ translations.tcDocuments }}
                </h5>
                <div id="" class="custom-list-section">
                  <div class="list-area">
                    <div class="card-table">
                      <div class="card-row" v-for="(item, index) in convention.documents" :key="index + 'item'">
                        <div class="table-item">
                          <div class="first-field">
                            <iPaperclip />
                            {{ item.evd_doc_name }}
                          </div>
                          <div class="third-field docOptions">
                            <div @click="editThisDoc(item)">
                              <ServiceEdit />
                            </div>
                            <div @click="deleteExistingDoc(item)">
                              <ServiceDelete />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-row" v-for="(item, index) in uploadedDocs" :key="index">
                        <div class="table-item">
                          <div class="first-field">
                            <iPaperclip />
                            {{ item.docName }}
                          </div>
                          <div class="third-field photoOptions">
                            <div @click="deleteNewDoc(index)">
                              <ServiceDelete />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showDocEdit" class="form-group">
                  <h5 class="custom-form-label">
                    {{ translations.tcDocumentName }}
                  </h5>
                  <b-form-input
                    :title="translations.tcDocumentName"
                    id="doc-name"
                    :placeholder="translations.tcDocumentName"
                    v-model="editDocFile.evd_doc_name"
                    required
                    class="mb-1"
                  ></b-form-input>
                  <b-button @click="onDocEdit" variant="primary" size="sm" class="btn btn-primary">
                    {{ translations.tcSave }}
                  </b-button>
                  <b-button @click="onDocCancel" size="sm" class="ml-1 btn btn-tertiary" variant="tertiary">
                    {{ translations.tcCancel }}
                  </b-button>
                </div>
                <div v-if="showDocUpload" class="form-group">
                  <h5 class="custom-form-label">
                    {{ translations.tcDocumentName }}
                  </h5>
                  <b-form-input
                    :title="translations.tcDocumentName"
                    id="doc-name"
                    :placeholder="translations.tcDocumentName"
                    v-model="docFileName"
                    required
                  >
                  </b-form-input>
                  <h5 class="custom-form-label mt-2">
                    {{ translations.tcFile }}
                  </h5>
                  <b-form-file
                    class="mb-1 mt-1"
                    style="text-transform: uppercase"
                    ref="fileUploadDoc"
                    v-model.lazy="docFile"
                    name="doc"
                    :browse-text="translations.tcBrowse"
                    :placeholder="translations.tcPhoto"
                    :drop-placeholder="translations.tcDropFileHere"
                    accept=".xls, .doc, .txt, .xlsx, .docx, .pdf"
                  />
                  <b-button @click="onDocUpload" variant="primary" size="sm" class="btn btn-primary">
                    {{ translations.tcSave }}
                  </b-button>
                  <b-button @click="onDocCancel" size="sm" class="ml-1 btn btn-tertiary" variant="tertiary">
                    {{ translations.tcCancel }}
                  </b-button>
                </div>
                <div class="divButton" @click="showDocUpload = true" v-if="!showDocUpload">
                  <b-button variant="primary" size="sm">
                    {{ translations.tcAddDocument }}
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6" class="flex-it">
              <div class="details section-8 section-left">
                <h5 class="main-title">{{ translations.tcLinks }}</h5>
                <div id="" class="custom-list-section">
                  <div class="list-area">
                    <div class="card-table">
                      <div class="card-row" v-for="(item, index) in convention.links" :key="index + 'oldlinks'">
                        <div class="table-item">
                          <div class="first-field">
                            <iPaperclip />
                            {{ item.evl_display_name }}
                          </div>
                          <div class="third-field photoOptions">
                            <div @click="editThisLink(item)">
                              <ServiceEdit />
                            </div>
                            <div @click="deleteExistingLink(item)">
                              <ServiceDelete />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-row" v-for="(item, index) in uploadedLinks" :key="index">
                        <div class="table-item">
                          <div class="first-field">
                            {{ item.displayName }}
                          </div>
                          <div class="third-field photoOptions">
                            <div @click="deleteNewLink(index)">
                              <ServiceDelete />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showLinkEdit" class="form-group">
                  <h5 class="custom-form-label">
                    {{ translations.tcLinkName }}
                  </h5>
                  <b-form-input
                    :title="translations.tcLinkName"
                    id="link-name"
                    :placeholder="translations.tcLinkName"
                    v-model="editLinkFile.evl_display_name"
                    required
                    class="mb-1"
                  ></b-form-input>
                  <h5 class="custom-form-label mt-2">
                    {{ translations.tcLinkURL }}
                  </h5>
                  <b-form-input
                    :title="translations.tcLinkURL"
                    id="link-url"
                    :placeholder="translations.tcLinkURL"
                    v-model="editLinkFile.evl_link_url"
                    class="mb-1"
                  ></b-form-input>
                  <b-button @click="onLinkEdit" variant="primary" size="sm" class="btn btn-primary">
                    {{ translations.tcSave }}
                  </b-button>
                  <b-button @click="onLinkCancel" size="sm" class="ml-1 btn btn-tertiary" variant="tertiary">
                    {{ translations.tcCancel }}
                  </b-button>
                </div>
                <div v-if="showLinkUpload" class="form-group">
                  <h5 class="custom-form-label">
                    {{ translations.tcLinkName }}
                  </h5>
                  <b-form-input
                    :title="translations.tcLinkName"
                    id="link-name"
                    :placeholder="translations.tcLinkName"
                    v-model="linkName"
                    required
                  ></b-form-input>

                  <h5 class="custom-form-label mt-2">
                    {{ translations.tcLinkURL }}
                  </h5>

                  <b-form-input
                    :title="translations.tcLinkURL"
                    id="link-url"
                    :placeholder="translations.tcLinkURL"
                    v-model="linkUrl"
                    class="mb-1"
                  ></b-form-input>

                  <b-button @click="onLinkUpload" variant="primary" size="sm" class="btn btn-primary">
                    {{ translations.tcSave }}
                  </b-button>
                  <b-button @click="onLinkCancel" size="sm" class="ml-1 btn btn-tertiary" variant="tertiary">
                    {{ translations.tcCancel }}
                  </b-button>
                </div>
                <div class="divButton">
                  <b-button variant="primary" size="sm" @click="showLinkUpload = true" v-if="!showLinkUpload">
                    {{ translations.tcAddLink }}
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col sm="6" class="flex-it">
              <div class="details section-9 common-section section-right">
                <h5 class="main-title">{{ translations.tcAdditionalInfo }}</h5>
                <div class="form-group">
                  <textarea
                    id="additional-info"
                    name="additional-info"
                    rows="4"
                    cols="50"
                    class="form-control"
                    v-model="convention.evn_additional_info"
                  >
                  </textarea>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <div class="details section-last">
                <div class="divButton">
                  <b-button variant="primary" class="btn-primary" size="sm" @click="handleSaveClick('S')">
                    {{ translations.tcSave }}
                  </b-button>
                  &nbsp;
                  <b-button variant="primary" class="btn-primary" size="sm" @click="handleSaveClick('SP')">
                    {{ translations.tcSaveAndPreview }}
                  </b-button>
                  &nbsp;
                  <b-button variant="light" size="sm" @click="handleCancelClick()">
                    {{ translations.tcCancel }}
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import ServiceEdit from '@/assets/svgs/service-card-edit.vue'
import ServiceDelete from '@/assets/svgs/service-card-delete.vue'
import iPaperclip from '@/assets/svgs/iPaperclip.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-convention',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      selectAState: 'Select a State',
      showPhotoUpload: false,
      showPhotoEdit: false,
      showDecadeNav: true,
      showDocUpload: false,
      showDocEdit: false,
      showLinkUpload: false,
      showLinkEdit: false,
      hideHeader: true,
      photoFile: null,
      photoFileName: null,
      editPhotoFile: null,
      uploadedPhotos: [],
      editDocFile: null,
      docFile: null,
      docFileName: null,
      uploadedDocs: [],
      linkName: null,
      linkUrl: null,
      editLinkFile: null,
      uploadedLinks: [],
      defaultEventTypeKey: '6AD38DDC-44BD-4953-820B-C96604190A4B',
      defaultEventTypeName: 'Convention',
      convention: {
        evn_key: null,
        evn_name: '',
        evn_start_date: null,
        evn_end_date: null,
        evn_additional_info: '',
        evn_registration_link: '',
        evn_state_assoc_key: null,
        evn_state_assoc_name: '',
        evn_delete_flag: false,
        evn_active_flag: false,
        evt_key: null,
        evt_description: '',
        evl_key: null,
        evl_name: '',
        evl_adr_line1: '',
        evl_adr_line2: null,
        evl_city: '',
        evl_state: null,
        evl_postal_code: '',
        evc_key: null,
        evc_name: '',
        evc_adr_line1: '',
        evc_adr_line2: null,
        evc_city: '',
        evc_state: null,
        evc_postal_code: '',
        evc_email: '',
        evc_phone_number: '',
        evc_other_info: '',
        evh_key: null,
        evh_name: '',
        evh_adr_line1: '',
        evh_adr_line2: null,
        evh_city: '',
        evh_state: null,
        evh_postal_code: '',
        evh_email: '',
        evh_phone_number: '',
        evh_website: '',
        photos: [],
        documents: [],
        links: [],
      },
    }
  },
  methods: {
    ...mapActions({
      addConvention: 'stateConvention/addConvention',
      editConventionDocument: 'stateConvention/editConventionDocument',
      editConventionPhoto: 'stateConvention/editConventionPhoto',
      editConventionLink: 'stateConvention/editConventionLink',
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      loadConventionInfo: 'stateConvention/loadConventionInfo',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedConventionKey: 'user/setSelectedConventionKey',
    }),
    async page_load() {
      this.setLoadingStatus(true)
      await Promise.all([await this.getTranslations(), this.resetConventionForm(), await this.loadAddressFormOptions()])
      if (!!this.userSelectedConventionKey) {
        await Promise.all([await this.loadConventionInfo(this.userSelectedConventionKey)]).then(() => {
          this.convention = { ...this.convention_info }
          this.convention.photos = [...this.convention_info.photos]
          this.convention.documents = [...this.convention_info.documents]
          this.convention.links = [...this.convention_info.links]
        })
      }
      this.selectAState = this.translations.tcState
      this.setLoadingStatus(false)
    },
    async resetConventionForm() {
      this.convention = { ...this.empty_convention }
      this.convention.photos = []
      this.convention.documents = []
      this.convention.links = []
    },
    async handleCancelClick() {
      this.setSelectedConventionKey(''), this.$router.push({ name: 'manage-events' })
    },
    async handleSaveClick(SorSP) {
      let response = null
      if (this.validateForm()) {
        let conventionPost = this.prepConvention(this.convention)
        await Promise.all([this.setLoadingStatus(true), (response = await this.addConvention(conventionPost))]).then(
          () => {
            this.setLoadingStatus(false)
            this.$swal({
              icon: response ? 'success' : 'error',
              text: response
                ? !this.userSelectedConventionKey
                  ? this.translations.tcConventionAdded
                  : this.translations.tcConventionEdited
                : this.translations.tcErrorDuringSave,
              confirmButtonText: this.translations.tcOk,
            }).then((result) => {
              this.setLoadingStatus(false)
              if (response) {
                if (SorSP === 'S') {
                  this.setSelectedConventionKey('')
                  this.$router.push({ name: 'manage-events' })
                } else {
                  this.setSelectedConventionKey(response)
                  this.$router.push({ name: 'convention-promo' })
                }
              }
            })
          }
        )
      }
    },
    validateForm() {
      let rtn = true
      if (
        !this.convention.evn_name ||
        !this.convention.evn_start_date ||
        !this.convention.evn_end_date ||
        !this.convention.evl_name
      ) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorRequiredFields,
          confirmButtonText: this.translations.tcOk,
        })
      }
      return rtn
    },
    prepConvention(con) {
      let preppedConvention = {
        event_key: con.evn_key ? con.evn_key : null,
        event_name: con.evn_name ? con.evn_name : null,
        event_type_key: con.evt_key ? con.evt_key : this.defaultEventTypeKey,
        event_type_name: con.evt_description ? con.evt_description : this.defaultEventTypeName,
        start_date: con.evn_start_date ? con.evn_start_date : null,
        end_date: con.evn_end_date ? con.evn_end_date : null,
        event_additional_info: con.evn_additional_info ? con.evn_additional_info : null,
        registration_link: con.evn_registration_link ? con.evn_registration_link : null,
        state_association_key: con.evn_state_assoc_key ? con.evn_state_assoc_key : null,
        state_association_name: con.evn_state_assoc_name ? con.evn_state_assoc_name : null,
        active_flag: con.evn_active_flag ? con.evn_active_flag : false,
        delete_flag: con.evn_delete_flag ? con.evn_delete_flag : false,

        location_key: con.evl_key ? con.evl_key : null,
        location_name: con.evl_name ? con.evl_name : null,
        location_address1: con.evl_adr_line1 ? con.evl_adr_line1 : null,
        location_address2: con.evl_adr_line2 ? con.evl_adr_line2 : null,
        location_city: con.evl_city ? con.evl_city : null,
        location_state: con.evl_state ? con.evl_state : null,
        location_postal_code: con.evl_postal_code ? con.evl_postal_code : null,

        contact_key: con.evc_key ? con.evc_key : null,
        contact_name: con.evc_name ? con.evc_name : null,
        contact_address1: con.evc_adr_line1 ? con.evc_adr_line1 : null,
        contact_address2: con.evc_adr_line2 ? con.evc_adr_line2 : null,
        contact_city: con.evc_city ? con.evc_city : null,
        contact_state: con.evc_state ? con.evc_state : null,
        contact_postal_code: con.evc_postal_code ? con.evc_postal_code : null,
        contact_email: con.evc_email ? con.evc_email : null,
        contact_phone: con.evc_phone_number ? con.evc_phone_number : null,
        contact_other_info: con.evc_other_info ? con.evc_other_info : null,

        hotel_key: con.evh_key ? con.evh_key : null,
        hotel_name: con.evh_name ? con.evh_name : null,
        hotel_address1: con.evh_adr_line1 ? con.evh_adr_line1 : null,
        hotel_address2: con.evh_adr_line2 ? con.evh_adr_line2 : null,
        hotel_city: con.evh_city ? con.evh_city : null,
        hotel_state: con.evh_state ? con.evh_state : null,
        hotel_postal_code: con.evh_postal_code ? con.evh_postal_code : null,
        hotel_email: con.evh_email ? con.evh_email : null,
        hotel_phone: con.evh_phone_number ? con.evh_phone_number : null,
        hotel_website: con.evh_website ? con.evh_website : null,
        photos: null,
        photos_names: null,
        documents: null,
        documents_names: null,
        links: null,
        links_names: null,
      }

      if (this.uploadedPhotos.length > 0) {
        let newPhotos = this.prepPhotos(con)
        preppedConvention.photos = [...newPhotos.photoFiles]
        preppedConvention.photos_names = [...newPhotos.photoNames]
      }
      if (this.uploadedDocs.length > 0) {
        let newDocs = this.prepDocs(con)
        preppedConvention.documents = [...newDocs.docFiles]
        preppedConvention.documents_names = [...newDocs.docNames]
      }
      if (this.uploadedLinks.length > 0) {
        let newLinks = this.prepLinks(con)
        preppedConvention.links_urls = [...newLinks.linkUrls]
        preppedConvention.links_names = [...newLinks.linkNames]
      }
      return preppedConvention
    },
    //PHOTO
    prepPhotos(con) {
      let rtnPhotos = { photoNames: [], photoFiles: [] }
      rtnPhotos.photoNames = this.uploadedPhotos.map((p) => p.photoName)
      rtnPhotos.photoFiles = this.uploadedPhotos.map((p) => p.photoFile)
      return rtnPhotos
    },
    editThisPhoto(photo) {
      this.editPhotoFile = { ...photo }
      this.showPhotoEdit = true
    },
    async onPhotoEdit() {
      if (!this.editPhotoFile.evp_image_name) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorPhotoNameRequired,
          confirmButtonText: this.translations.tcOk,
        })
        return
      }

      let response = false
      var payload = {
        photo_key: this.editPhotoFile.evp_key,
        event_key: this.editPhotoFile.evp_evn_key,
        user_key: null,
        photo_name: this.editPhotoFile.evp_image_name,
        photo_url_path: this.editPhotoFile.evp_image_url_path,
        delete_flag: false,
      }
      await Promise.all([
        this.setLoadingStatus(true),
        (response = await this.editConventionPhoto(payload)),
        this.setLoadingStatus(false),
      ]).then(() => {
        if (!response) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorUpdatingPhotoName,
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {
            return
          })
        } else {
          const photoIndex = this.convention.photos.findIndex((p) => p.evp_key === this.editPhotoFile.evp_key)
          if (photoIndex >= 0) {
            this.convention.photos[photoIndex].evp_image_name = this.editPhotoFile.evp_image_name
          }
          this.onPhotoCancel()
        }
      })
    },

    deleteExistingPhoto(item) {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        title: this.translations.tcOkayToDelete,
        html: `<i>${this.translations.tcIsThatOkay}</i>`,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel,
      }).then((result) => {
        if (!!result.value) {
          this.handleDeletePhoto(item)
        }
      })
    },
    async handleDeletePhoto(item) {
      let response = false
      var payload = {
        photo_key: item.evp_key,
        event_key: item.evp_evn_key,
        user_key: null,
        photo_name: item.evp_image_name,
        photo_url_path: item.evp_image_url_path,
        delete_flag: true,
      }
      await Promise.all([
        this.setLoadingStatus(true),
        (response = await this.editConventionPhoto(payload)),
        this.setLoadingStatus(false),
      ]).then(() => {
        if (!response) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorUpdatingPhotoName,
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {
            return
          })
        } else {
          const photoIndex = this.convention.photos.findIndex((p) => p.evp_key === item.evp_key)
          if (photoIndex >= 0) {
            this.convention.photos.splice(photoIndex, 1)
          }
        }
      })
    },
    deleteNewPhoto(id) {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        title: this.translations.tcOkayToDelete,
        html: this.translations.tcIsThatOkay,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel,
      }).then((result) => {
        if (!!result.value) {
          this.uploadedPhotos.splice(id, 1)
        }
      })
    },
    onPhotoUpload() {
      if (!this.photoFileName || !this.photoFile) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorPhotoNameAndFileRequired,
          confirmButtonText: this.translations.tcOk,
        })
        return
      }

      let newPhoto = { photoName: this.photoFileName, photoFile: this.photoFile }
      this.uploadedPhotos.push(newPhoto)
      this.onPhotoCancel()
    },
    onPhotoCancel() {
      this.showPhotoEdit = false
      this.showPhotoUpload = false
      this.photoFile = null
      this.photoFileName = null
      this.editPhotoFile = null
    },

    //LINKS
    prepLinks(con) {
      let rtnLinks = { linkNames: [], linkUrls: [] }
      rtnLinks.linkNames = this.uploadedLinks.map((l) => l.displayName)
      rtnLinks.linkUrls = this.uploadedLinks.map((l) => l.linkURL)
      return rtnLinks
    },
    editThisLink(link) {
      this.editLinkFile = { ...link }
      this.showLinkEdit = true
    },
    async onLinkEdit() {
      if (!this.editLinkFile.evl_display_name || !this.editLinkFile.evl_link_url) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcLinkNameAndURLRequired,
          confirmButtonText: this.translations.tcOk,
        })
        return
      }

      let response = false
      var payload = {
        link_key: this.editLinkFile.evl_key,
        event_key: this.editLinkFile.evl_evn_key,
        user_key: null,
        link_display_name: this.editLinkFile.evl_display_name,
        link_url: this.editLinkFile.evl_link_url,
        delete_flag: false,
      }
      await Promise.all([
        this.setLoadingStatus(true),
        (response = await this.editConventionLink(payload)),
        this.setLoadingStatus(false),
      ]).then(() => {
        if (!response) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorUpdatingLinkName,
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {
            return
          })
        } else {
          const linkIndex = this.convention.links.findIndex((l) => l.evl_key === this.editLinkFile.evl_key)
          if (linkIndex >= 0) {
            this.convention.links[linkIndex].evl_display_name = this.editLinkFile.evl_display_name
            this.convention.links[linkIndex].evl_link_url = this.editLinkFile.evl_link_url
          }
          this.onLinkCancel()
        }
      })
    },
    onLinkCancel() {
      this.showLinkEdit = false
      this.showLinkUpload = false
      this.linkName = null
      this.linkUrl = null
      this.editLinkFile = null
    },
    onLinkUpload() {
      if (!this.linkName || !this.linkUrl) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcLinkNameAndURLRequired,
          confirmButtonText: this.translations.tcOk,
        })
        return
      }

      let newLink = { displayName: this.linkName, linkURL: this.linkUrl }
      this.uploadedLinks.push(newLink)
      this.onLinkCancel()
    },
    deleteNewLink(id) {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        title: this.translations.tcOkayToDeleteLink,
        html: this.translations.tcIsThatOkay,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel,
      }).then((result) => {
        if (!!result.value) {
          this.uploadedLinks.splice(id, 1)
        }
      })
    },
    deleteExistingLink(item) {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        title: this.translations.tcOkayToDeleteLink,
        html: `<i>${this.translations.tcIsThatOkay}</i>`,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel,
      }).then((result) => {
        if (!!result.value) {
          this.handleDeleteLink(item)
        }
      })
    },
    async handleDeleteLink(item) {
      let response = false
      var payload = {
        link_key: item.evl_key,
        event_key: item.evl_evn_key,
        user_key: null,
        link_display_name: item.evl_display_name,
        link_url: item.evl_link_url,
        delete_flag: true,
      }
      await Promise.all([
        this.setLoadingStatus(true),
        (response = await this.editConventionLink(payload)),
        this.setLoadingStatus(false),
      ]).then(() => {
        if (!response) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorUpdatingLinkName,
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {
            return
          })
        } else {
          const linkIndex = this.convention.links.findIndex((l) => l.evl_key === item.evl_key)
          if (linkIndex >= 0) {
            this.convention.links.splice(linkIndex, 1)
          }
        }
      })
    },

    //DOCUMENTS
    prepDocs(con) {
      let rtnDocs = { docNames: [], docFiles: [] }
      rtnDocs.docNames = this.uploadedDocs.map((d) => d.docName)
      rtnDocs.docFiles = this.uploadedDocs.map((d) => d.docFile)
      return rtnDocs
    },
    async deleteExistingDoc(item) {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        title: this.translations.tcOkayToDeleteDoc,
        html: `<i>${this.translations.tcIsThatOkay}</i>`,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel,
      }).then((result) => {
        if (result.value) {
          this.handleDeleteDoc(item)
        }
      })
    },
    async handleDeleteDoc(item) {
      let response = false
      var payload = {
        doc_key: item.evd_key,
        event_key: item.evd_evn_key,
        user_key: null,
        doc_name: item.evd_doc_name,
        doc_url_path: item.evd_doc_url_path,
        delete_flag: true,
      }
      await Promise.all([
        this.setLoadingStatus(true),
        (response = await this.editConventionDocument(payload)),
        this.setLoadingStatus(false),
      ]).then(() => {
        if (!response) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorUpdatingDocName,
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {
            return
          })
        } else {
          const docIndex = this.convention.documents.findIndex((d) => d.evd_key === item.evd_key)
          if (docIndex >= 0) {
            this.convention.documents.splice(docIndex, 1)
          }
        }
      })
    },
    deleteNewDoc(id) {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        title: this.translations.tcPermDeleteTheDoc,
        html: this.translations.tcIsThatOkay,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel,
      }).then((result) => {
        if (!!result.value) {
          this.uploadedDocs.splice(id, 1)
        }
      })
    },
    editThisDoc(doc) {
      this.editDocFile = { ...doc }
      this.showDocEdit = true
    },
    async onDocEdit() {
      if (!this.editDocFile.evd_doc_name) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcDocNameRequired,
          confirmButtonText: this.translations.tcOk,
        })
        return
      }

      let response = false
      var payload = {
        doc_key: this.editDocFile.evd_key,
        event_key: this.editDocFile.evd_evn_key,
        user_key: null,
        doc_name: this.editDocFile.evd_doc_name,
        doc_url_path: this.editDocFile.evd_doc_url_path,
        delete_flag: false,
      }
      await Promise.all([
        this.setLoadingStatus(true),
        (response = await this.editConventionDocument(payload)),
        this.setLoadingStatus(false),
      ]).then(() => {
        if (!response) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorUpdatingDocName,
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {
            return
          })
        } else {
          const docIndex = this.convention.documents.findIndex((d) => d.evd_key === this.editDocFile.evd_key)
          if (docIndex >= 0) {
            this.convention.documents[docIndex].evd_doc_name = this.editDocFile.evd_doc_name
          }
          this.onDocCancel()
        }
      })
    },

    onDocUpload() {
      if (!this.docFileName || !this.docFile) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorDocNameAndFileRequired,
          confirmButtonText: this.translations.tcOk,
        })
        return
      }

      let newDoc = { docName: this.docFileName, docFile: this.docFile }
      this.uploadedDocs.push(newDoc)
      this.onDocCancel()
    },
    onDocCancel() {
      this.showDocEdit = false
      this.showDocUpload = false
      this.docFile = null
      this.docFileName = null
      this.editDocFile = false
    },
  },
  computed: {
    ...mapGetters({
      userSelectedConventionKey: 'user/userSelectedConventionKey',
      convention_info: 'stateConvention/convention_info',
      empty_convention: 'stateConvention/empty_convention',
      convertedAddressFormOptions: 'membership/convertedAddressFormOptions',
      prefCulture: 'user/userPreferredCulture',
    }),
    optionsStates() {
      return [
        { value: null, text: this.selectAState, disabled: true },
        ...this.convertedAddressFormOptions.states_long.map((x) => {
          return {
            text: x.text,
            value: x.code,
          }
        }),
      ]
    },
  },
  async created() {
    await this.page_load()
  },
  components: {
    ServiceEdit: ServiceEdit,
    ServiceDelete: ServiceDelete,
    iPaperclip: iPaperclip,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/views/ConventionPromoEdit.scss';

div.row {
  margin-top: 10px;
}
#example-datepicker__outer_ .btn {
  color: #212529;
}
.primary {
  margin-bottom: 35px;
  > .col {
    @include breakpoint(sm) {
      flex: 0 0 auto;
      width: 100%;
    }
  }
}

h2 {
  font-family: $DIN;
  text-transform: uppercase;
}

h5 {
  text-transform: uppercase;
}

.photoOptions,
.docOptions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > div {
    margin-right: 10px;
    &:hover {
      opacity: 0.6;
    }
    svg {
      cursor: pointer;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}
.date-picker {
  border: 1px solid #003946;
  color: #003946;
  @include breakpoint(mobile) {
    width: 50%;
  }
}

.vdp-datepicker div input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
  color: green;
}
</style>
